exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-compare-js": () => import("./../../../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-data-js": () => import("./../../../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-delta-template-js": () => import("./../../../src/templates/deltaTemplate.js" /* webpackChunkName: "component---src-templates-delta-template-js" */),
  "component---src-templates-device-detail-template-js": () => import("./../../../src/templates/deviceDetailTemplate.js" /* webpackChunkName: "component---src-templates-device-detail-template-js" */),
  "component---src-templates-markdown-template-js": () => import("./../../../src/templates/markdownTemplate.js" /* webpackChunkName: "component---src-templates-markdown-template-js" */)
}

